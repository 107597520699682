/*
  Usage:
  @include font(type-face, font-weight, font-style);
*/
@font-face{
  font-family: "MinionPro-Regular";
  src:url("#{$font-path}35d9d14d-a31d-4d9b-a998-00ffc9bd46a4.eot?#iefix");
  src:url("#{$font-path}35d9d14d-a31d-4d9b-a998-00ffc9bd46a4.eot?#iefix") format("eot"),
  url("#{$font-path}18597b82-9a06-46a3-b68b-c250c1105515.woff2") format("woff2"),
  url("#{$font-path}62100580-a2d1-4d98-bb14-d4c4c3f1a5ba.woff") format("woff"),
  url("#{$font-path}7ae8e168-1c29-4fcb-bb59-0043c943df3b.ttf") format("truetype"),
  url("#{$font-path}cfa664d4-e518-4a49-b8a3-fccec93c29c1.svg#cfa664d4-e518-4a49-b8a3-fccec93c29c1") format("svg");
}

@mixin font-sans-serif($type-face: $font-sans-serif, $font-weight: $font-weight-normal, $font-style: normal) {
  font-family: $type-face;
  font-weight: $font-weight;
  font-style: $font-style;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin font-serif($type-face: $font-serif, $font-weight: $font-weight-normal, $font-style: normal) {
  font-family: $type-face;
  font-weight: $font-weight;
  font-style: $font-style;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

