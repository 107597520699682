/*
  Usage:
  if $print has no value, @media print won't be used
  if $print has true, @media print will be used
  @include xl-min($print) {

  }
*/

@mixin xs-min($print: false) {
  @include mq-min-print(map-get($layout-breakpoints, xs), $print) {
    @content;
  }
}

/*
  Usage:
  if $print has no value, @media print won't be used
  if $print has true, @media print will be used
  @include sm-min($print) {

  }
*/
@mixin sm-min($print: false) {
  @include mq-min-print(map-get($layout-breakpoints, sm), $print) {
    @content;
  }
}

/*
  Usage:
  if $print has no value, @media print won't be used
  if $print has true, @media print will be used
  @include md-min($print) {

  }
*/
@mixin md-min($print: false) {
  @include mq-min-print(map-get($layout-breakpoints, md), $print) {
    @content;
  }
}

/*
  Usage:
  if $print has no value, @media print won't be used
  if $print has true, @media print will be used
  @include lg-min($print) {

  }
*/
@mixin lg-min($print: false) {
  @include mq-min-print(map-get($layout-breakpoints, lg), $print) {
    @content;
  }
}

/*
  Usage:
  if $print has no value, @media print won't be used
  if $print has true, @media print will be used
  @include xl-min($print) {

  }
*/
@mixin xl-min($print: false) {
  @include mq-min-print(map-get($layout-breakpoints, xl), $print) {
    @content;
  }
}

@mixin xxl-min($print: false) {
  @include mq-min-print(map-get($layout-breakpoints, xxl), $print) {
    @content;
  }
}

/*
  Usage:
  if $print has no value, @media print won't be used
  if $print has true, @media print will be used
  @include xs-max($print) {

  }
*/
@mixin xs-max($print: false) {
  @include mq-max-print(map-get($layout-breakpoints, xs), $print) {
    @content;
  }
}

/*
  Usage:
  if $print has no value, @media print won't be used
  if $print has true, @media print will be used
  @include sm-max($print) {

  }
*/
@mixin sm-max($print: false) {
  @include mq-max-print(map-get($layout-breakpoints, sm), $print) {
    @content;
  }
}

/*
  Usage:
  if $print has no value, @media print won't be used
  if $print has true, @media print will be used
  @include md-max($print) {

  }
*/
@mixin md-max($print: false) {
  @include mq-max-print(map-get($layout-breakpoints, md), $print) {
    @content;
  }
}

/*
  Usage:
  if $print has no value, @media print won't be used
  if $print has true, @media print will be used
  @include lg-max($print) {

  }
*/
@mixin lg-max($print: false) {
  @include mq-max-print(map-get($layout-breakpoints, lg), $print) {
    @content;
  }
}

/*
  Usage:
  if $print has no value, @media print won't be used
  if $print has true, @media print will be used
  @include xl-max($print) {

  }
*/
@mixin xl-max($print: false) {
  @include mq-max-print(map-get($layout-breakpoints, xl), $print) {
    @content;
  }
}


/*
  Helpers do not use
*/
@mixin mq-min-print($breakpoint, $print: false) {
  @media (min-width: $breakpoint) {
    @content;
  }

  @if $print == true {
    @media print {
      @content
    }
  }
}

@mixin mq-max-print($breakpoint, $print: false) {

  @media (max-width: '#{$breakpoint - 1}') {
    @content;
  }

  @if $print == true {
    @media print {
      @content
    }
  }
}
