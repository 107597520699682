/*
  Usage:
  @include responsive-ratio(width%, ratiowidth, ratioheight, 'childelement as string');
*/
@mixin responsive-ratio($cw: 100%, $rw: 16, $rh: 9, $c: 'iframe') {
  position: relative;
  width: $cw;
  height: 0;
  padding: (($rh / $rw) * $cw) 0 0 0;
  display: block;

  @if $c != null {
    #{$c} {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }
}
