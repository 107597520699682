@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
*, *:before, *:after {
  box-sizing: border-box;
  position: relative; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*
  Gets rid of the annoying outline for mouse users but preserves it for keyboard users, and is ignored by browsers that don’t support :focus-visible.
  @Link: https://twitter.com/LeaVerou/status/1045768279753666562
 */
:focus:not(:focus-visible) {
  outline: none; }

.sr {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  white-space: nowrap; }

.no-tab *:focus {
  outline: 0;
  box-shadow: none; }

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0; }

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none; }

/*
  Usage:
  @extend %clearfix;
*/
/*
  Usage:
  @include vertical-align-middle(position, width);
*/
/*
  Usage:
  @include vertical-align(position);
*/
/*
  Usage:
  if $print has no value, @media print won't be used
  if $print has true, @media print will be used
  @include xl-min($print) {

  }
*/
/*
  Usage:
  if $print has no value, @media print won't be used
  if $print has true, @media print will be used
  @include sm-min($print) {

  }
*/
/*
  Usage:
  if $print has no value, @media print won't be used
  if $print has true, @media print will be used
  @include md-min($print) {

  }
*/
/*
  Usage:
  if $print has no value, @media print won't be used
  if $print has true, @media print will be used
  @include lg-min($print) {

  }
*/
/*
  Usage:
  if $print has no value, @media print won't be used
  if $print has true, @media print will be used
  @include xl-min($print) {

  }
*/
/*
  Usage:
  if $print has no value, @media print won't be used
  if $print has true, @media print will be used
  @include xs-max($print) {

  }
*/
/*
  Usage:
  if $print has no value, @media print won't be used
  if $print has true, @media print will be used
  @include sm-max($print) {

  }
*/
/*
  Usage:
  if $print has no value, @media print won't be used
  if $print has true, @media print will be used
  @include md-max($print) {

  }
*/
/*
  Usage:
  if $print has no value, @media print won't be used
  if $print has true, @media print will be used
  @include lg-max($print) {

  }
*/
/*
  Usage:
  if $print has no value, @media print won't be used
  if $print has true, @media print will be used
  @include xl-max($print) {

  }
*/
/*
  Helpers do not use
*/
/*
  Usage:
  @include flexbox(direction, wrap, justify-content, align-content);
*/
/*
  Usage:
  @include font(type-face, font-weight, font-style);
*/
@font-face {
  font-family: "MinionPro-Regular";
  src: url("../fonts/35d9d14d-a31d-4d9b-a998-00ffc9bd46a4.eot?#iefix");
  src: url("../fonts/35d9d14d-a31d-4d9b-a998-00ffc9bd46a4.eot?#iefix") format("eot"), url("../fonts/18597b82-9a06-46a3-b68b-c250c1105515.woff2") format("woff2"), url("../fonts/62100580-a2d1-4d98-bb14-d4c4c3f1a5ba.woff") format("woff"), url("../fonts/7ae8e168-1c29-4fcb-bb59-0043c943df3b.ttf") format("truetype"), url("../fonts/cfa664d4-e518-4a49-b8a3-fccec93c29c1.svg#cfa664d4-e518-4a49-b8a3-fccec93c29c1") format("svg"); }

/*
  Usage:
  @include font-size(font-settings-variable);
*/
/*
  HELPER MIXINS (do not use)
*/
/*
  Usage:
  Livingdocs component margin-bottom
*/
/*
  Usage:
  @include responsive-ratio(width%, ratiowidth, ratioheight, 'childelement as string');
*/
/*
  Usage:
  @include size(width unit, height unit);
*/
html {
  font-size: 62.5%; }

body {
  font-family: "Font Name (placeholder)", sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  @media (min-width: 1370px) {
    body {
      overflow: hidden; } }
  body:not(.no-focus) *:focus {
    outline: #4bbb87 auto 1px; }

*, *:before, *:after {
  box-sizing: border-box; }

img {
  max-width: 100%;
  height: auto;
  display: block; }
  img.lazy {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease; }
  img.loaded {
    opacity: 1;
    visibility: visible; }

.ns-bold,
strong,
b {
  font-weight: 700; }

.superscript,
.subscript,
sup,
sub {
  font-size: 60%;
  line-height: 0;
  vertical-align: baseline;
  position: relative;
  padding: 0 1px; }

.superscript,
sup {
  top: -0.6em; }

sub,
.subscript {
  top: 0.4em; }

.no-focus *:focus {
  outline: 0; }

.language-switch {
  position: relative;
  z-index: 10;
  background: #ffffff; }
  @media (min-width: 1370px) {
    .language-switch {
      position: absolute;
      top: 0;
      right: 20px;
      background: transparent; } }

.language-switch__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start; }
  @media (min-width: 1370px) {
    .language-switch__list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start; } }

.language-switch__link {
  font-size: 16px;
  letter-spacing: 1px;
  color: #000000;
  padding: 10px 15px;
  display: block;
  text-decoration: none; }
  @media (min-width: 1370px) {
    .language-switch__link {
      padding: 25px 10px;
      color: #ffffff; } }
  @media (min-width: 1665px) {
    .language-switch__link {
      padding-top: 50px;
      padding-bottom: 50px; } }

.language-switch__item--active {
  color: #A88A68; }

html {
  background: #291F1D; }

.content {
  overflow: hidden;
  font-family: "MinionPro-Regular", serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  @media (min-width: 1370px) {
    .content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: stretch;
      align-items: flex-start;
      height: 100vh; } }

@media (max-width: 1369px) {
  .content__video-container {
    margin-top: -19px;
    position: relative;
    width: 100%;
    height: 0;
    padding: 100% 0 0 0;
    display: block; }
    .content__video-container iframe {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%; } }

@media (min-width: 1370px) {
  .content__video-container {
    position: relative;
    width: 100vw;
    height: 100vh; } }

@media (min-width: 1665px) {
  .content__video-container {
    width: 100vw; } }

.content__video {
  max-width: 100%; }
  @media (max-width: 1369px) {
    .content__video {
      position: absolute;
      top: 0;
      left: 0; } }
  @media (min-width: 1370px) {
    .content__video {
      min-width: calc(100vw - 400px);
      min-height: 100vh;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 100%;
      object-fit: cover; } }
  @media (min-width: 1665px) {
    .content__video {
      top: 35%;
      min-width: calc(100vw - 600px); } }

.content__company {
  background: rgba(255, 255, 255, 0.9);
  color: #000000;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 100%);
  padding: 25px;
  font-size: 30px;
  text-align: center;
  max-width: 450px;
  transition: transform 0.4s ease 1.5s; }
  .loaded .content__company {
    transform: translate(-50%, 0); }

.content__company--desktop {
  display: none; }
  @media (min-width: 1370px) {
    .content__company--desktop {
      display: block; } }

.content__company--mobile {
  position: relative;
  left: 0;
  z-index: 1;
  transform: translate(0, 0);
  max-width: 100%;
  font-size: 20px; }
  .loaded .content__company--mobile {
    transform: translate(0, 0); }
  @media (min-width: 1370px) {
    .content__company--mobile {
      display: none; } }

.content__content {
  padding: 25px;
  color: #ffffff;
  background: transparent;
  position: relative;
  margin: -100px auto 0 auto; }
  @media (min-width: 992px) {
    .content__content {
      margin: -100px auto 0 auto; } }
  @media (min-width: 1024px) {
    .content__content {
      width: 400px;
      height: calc(100vh);
      left: 50%;
      transform: translateX(-50%);
      position: fixed;
      top: 0;
      margin: 100px 0; } }
  @media (min-width: 1665px) {
    .content__content {
      padding: 50px;
      width: 600px;
      margin: 0; } }

.content__content-inner {
  margin: 0 auto;
  max-width: 320px; }
  @media (min-width: 1370px) {
    .content__content-inner {
      margin: 0;
      max-width: 100%; } }

.content__title {
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1px;
  margin-bottom: 0;
  margin-left: 40px;
  width: 0;
  opacity: 0;
  display: block;
  transition: width 0.4s ease 0.3s; }
  @media (min-width: 1370px) {
    .content__title {
      margin-left: 20px; } }
  @media (min-width: 1665px) {
    .content__title {
      margin-left: 40px; } }
  .loaded .content__title {
    width: auto;
    opacity: 1; }

.content__sub-title {
  font-size: 50px;
  font-style: italic;
  display: block;
  color: #ffffff;
  line-height: 1;
  opacity: 0;
  transition: opacity 0.4s ease 0.9s, transform 0.4s ease 0.9s; }
  @media (min-width: 1370px) {
    .content__sub-title {
      font-size: 55px; } }
  .loaded .content__sub-title {
    opacity: 1; }

.content__sub-title--passion {
  padding-top: 10px; }
  .loaded .content__sub-title--passion {
    transform: translateX(45px); }
    @media (min-width: 1370px) {
      .loaded .content__sub-title--passion {
        transform: translateX(20px); } }

.loaded .content__sub-title--creation {
  transform: translateX(90px); }
  @media (min-width: 1370px) {
    .loaded .content__sub-title--creation {
      transform: translateX(90px); } }

.content__sub-title--vision {
  transform: translateX(30px); }
  @media (min-width: 1370px) {
    .loaded .content__sub-title--vision {
      transform: translateX(0); } }

.content__sub-title--emotion {
  padding-bottom: 20px;
  transform: translateX(70px); }
  @media (min-width: 1370px) {
    .loaded .content__sub-title--emotion {
      transform: translateX(50px); } }

.content__years {
  margin-left: 130px;
  margin-bottom: 40px;
  opacity: 0;
  width: 0;
  transition: opacity 0.4s ease 1.2s, transform 0.4s ease 1.2s, width 0.4s ease 1.2s; }
  @media (min-width: 1370px) {
    .content__years {
      margin-left: 80px; } }
  @media (min-width: 1665px) {
    .content__years {
      margin-left: 130px; } }
  .loaded .content__years {
    opacity: 1;
    width: auto;
    transform: translateX(15px); }

.content__year {
  font-size: 70px; }

.content__history {
  font-size: 30px;
  line-height: 1.05; }

.content__button {
  color: #561D0F;
  background: #c69843;
  background: radial-gradient(circle, #c69843 20%, #e4cb71 98%);
  transition: background .4s ease;
  display: inline-block;
  padding: 10px 15px;
  text-decoration: none;
  font-size: 20px;
  -webkit-appearance: none;
  border: none;
  margin-left: 130px;
  opacity: 0;
  width: 0;
  color: #ffffff;
  transition: opacity 0.4s ease 1.5s, transform 0.4s ease 1.5s, width 0.4s ease 1.5s, background 0.4s ease;
  margin-top: 0; }
  @media (min-width: 1370px) {
    .content__button {
      margin-left: 80px; } }
  @media (min-width: 1665px) {
    .content__button {
      margin-left: 130px; } }
  .content__button:after {
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    transition: opacity .8s ease;
    background: radial-gradient(circle, #e4cb71 40%, #c69843 78%);
    opacity: 0;
    z-index: 1; }
  .content__button > span {
    z-index: 2;
    position: relative; }
  .content__button:hover:after {
    opacity: 1; }
  .loaded .content__button {
    opacity: 1;
    width: auto;
    transform: translateX(15px); }

.lightbox {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #85714D;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.4s ease; }

.lightbox--is-visible {
  opacity: 1;
  z-index: 10; }

.lightbox__backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.lightbox__close-btn {
  -webkit-appearance: none;
  border: none;
  background: none;
  color: #ffffff;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  top: 3px;
  right: 3px;
  width: 44px;
  height: 44px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }
  @media (min-width: 1370px) {
    .lightbox__close-btn {
      top: 5px;
      right: 5px; } }
  .lightbox__close-btn .icon {
    width: 25px;
    height: auto; }
    @media (min-width: 1370px) {
      .lightbox__close-btn .icon {
        width: 25px; } }

.lightbox__content {
  background: white;
  padding: 25px;
  margin: 25px;
  height: calc(100vh - 50px); }
  @media (min-width: 1370px) {
    .lightbox__content {
      padding: 0;
      margin: 45px 90px;
      height: calc(100vh - 90px); } }
