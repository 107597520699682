html {
  font-size: 62.5%;
}

body {
  @include font-serif($font-sans-serif, $font-weight-normal);

  @include xl-min() {
    overflow: hidden;
  }

  &:not(.no-focus) {
    *:focus{
      outline: $color-primary auto 1px;
    }
  }
}

*, *:before, *:after{
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
  display: block;

  &.lazy {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease;
  }

  &.loaded {
    opacity: 1;
    visibility: visible;
  }
}

.ns-bold,
strong,
b {
  font-weight: $font-weight-bold;
}

.superscript,
.subscript,
sup,
sub {
  font-size: 60%;
  line-height: 0;
  vertical-align: baseline;
  position: relative;
  padding: 0 1px;
}

.superscript,
sup {
  top: -0.6em;
}

sub,
.subscript {
  top: 0.4em;
}

.no-focus{
  *:focus{
    outline: 0;
  }
}
