.language-switch {
  position: relative;
  z-index: 10;
  background: $color-white;

  @include xl-min() {
    position: absolute;
    top: 0;
    right: 20px;
    background: transparent;
  }
}

.language-switch__list {
  list-style: none;
  margin: 0;
  padding: 0;
  @include flexbox($justify-content: center);

  @include xl-min() {
    @include flexbox();
  }
}

.language-switch__link {
  font-size: 16px;
  letter-spacing: 1px;
  color: $color-black;
  padding: 10px 15px;
  display: block;
  text-decoration: none;

  @include xl-min() {
    padding: 25px 10px;
    color: $color-white;
  }

  @include xxl-min() {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.language-switch__item--active {
  color: $color-gold;
}
