html{
  background: #291F1D;
}

.content {
  overflow: hidden;
  @include font-serif();

  @include xl-min() {
    @include flexbox($justify-content: stretch);
    height: 100vh;
  }
}
.content__video-container {
  @include xl-max() {
    margin-top: -19px;
    @include responsive-ratio(100%, 1, 1);
  }

  @include xl-min {
    position: relative;
    width: 100vw;
    height: 100vh;
  }

  @include xxl-min() {
    width: 100vw;
  }
}

.content__video {
  max-width: 100%;

  @include xl-max() {
    position: absolute;
    top: 0;
    left: 0;
  }

  @include xl-min {
    min-width: calc(100vw - 400px);
    min-height: 100vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    object-fit: cover;
  }
  
  @include xxl-min() {
    top: 35%;
    min-width: calc(100vw - 600px);
  }
}

.content__company {
  background: rgba(255,255,255,0.9);
  color: $color-black;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 100%);
  padding: 25px;
  font-size: 30px;
  text-align: center;
  max-width: 450px;
  transition: transform 0.4s ease 1.5s;

  .loaded &  {
    transform: translate(-50%, 0);
  }
}

.content__company--desktop {
  display: none;

  @include xl-min() {
    display: block;
  }
}
.content__company--mobile {
  position: relative;
  left: 0;
  z-index: 1;
  transform: translate(0, 0);
  max-width: 100%;
  font-size: 20px;

  .loaded &  {
    transform: translate(0, 0);
  }

  @include xl-min() {
    display: none;
  }
}

.content__content {
  padding: 25px;
  color: $color-white;
  background: transparent ;
  position: relative;
  margin: -100px auto 0 auto;
  
  @include md-min{
    margin: -100px auto 0 auto;
  }
  
  @include lg-min() {
    width: 400px;
    height: calc(100vh);
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    top: 0;
    margin: 100px 0;
  }

  @include xxl-min() {
    padding: 50px;
    width: 600px;
    margin: 0;
  }
}

.content__content-inner {
  margin: 0 auto;
  max-width: 320px;

  @include xl-min() {
    margin: 0;
    max-width: 100%;
  }
}

.content__title {
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1px;
  margin-bottom: 0;
  margin-left: 40px;
  width: 0;
  opacity: 0;
  display: block;
  transition: width 0.4s ease 0.3s;

  @include xl-min() {
    margin-left: 20px;
  }

  @include xxl-min() {
    margin-left: 40px;
  }

  .loaded & {
    width: auto;
    opacity: 1;
  }

  
}

.content__sub-titles {
}

.content__sub-title {
  font-size: 50px;
  font-style: italic;
  display: block;
  color: $color-white;
  line-height: 1;
  opacity: 0;
  transition: opacity 0.4s ease 0.9s, transform 0.4s ease 0.9s;

  @include xl-min() {
    font-size: 55px;
  }

  @include xxl-min() {
    //font-size: 55px;
  }

  .loaded & {
    opacity: 1;
  }
}

.content__sub-title--passion {
  padding-top: 10px;

  .loaded & {
    transform: translateX(45px);

    @include xl-min() {
      transform: translateX(20px);
    }
  }
}

.content__sub-title--creation {
  .loaded & {
    transform: translateX(90px);

    @include xl-min() {
      transform: translateX(90px);
    }
  }
}

.content__sub-title--vision {
  transform: translateX(30px);

  .loaded & {
    @include xl-min() {
      transform: translateX(0);
    }
  }
}

.content__sub-title--emotion {
  padding-bottom: 20px;
  transform: translateX(70px);

  .loaded & {
    @include xl-min() {
      transform: translateX(50px);
    }
  }
}

.content__years {
  margin-left: 130px;
  margin-bottom: 40px;
  opacity: 0;
  width: 0;
  transition: opacity 0.4s ease 1.2s, transform 0.4s ease 1.2s, width 0.4s ease 1.2s;

  @include xl-min() {
    margin-left: 80px;
  }

  @include xxl-min() {
    margin-left: 130px;
  }

  .loaded & {
    opacity: 1;
    width: auto;
    transform: translateX(15px);
  }
}

.content__year {
  font-size: 70px;
}

.content__history {
  font-size: 30px;
  line-height: 1.05;
}

.content__button {
  color: $color-brown-dark;
  background: rgb(198,152,67);
  background: radial-gradient(circle, rgba(198,152,67,1) 20%, rgba(228,203,113,1) 98%);
  transition: background .4s ease;
  display: inline-block;
  padding: 10px 15px;
  text-decoration: none;
  font-size: 20px;
  -webkit-appearance: none;
  border: none;
  margin-left: 130px;
  opacity: 0;
  width: 0;
  color: $color-white;
  transition: opacity 0.4s ease 1.5s, transform 0.4s ease 1.5s, width 0.4s ease 1.5s, background 0.4s ease;
  margin-top: 0;


  @include xl-min() {
    margin-left: 80px;
  }

  @include xxl-min() {
    margin-left: 130px;
  }

  &:after{
    content: '';
    height: 100%;
    width: 100%;
    top: 0; 
    left: 0;
    position: absolute;
    transition: opacity .8s ease;
    background: radial-gradient(circle, rgba(228,203,113,1) 40%, rgba(198,152,67,1) 78%);
    opacity: 0;
    z-index: 1;
  }

  & > span{
    z-index: 2;
    position: relative;
  }

  &:hover {
    &:after{
      opacity: 1;
    }
  }

  .loaded & {
    opacity: 1;
    width: auto;
    transform: translateX(15px);
  }
}
