.lightbox {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $color-gold-lightbox;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.4s ease;
}

.lightbox--is-visible {
  opacity: 1;
  z-index: 10;
}

.lightbox__backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.lightbox__close-btn {
  -webkit-appearance: none;
  border: none;
  background: none;
  color: $color-white;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  top: 3px;
  right: 3px;
  width: 44px;
  height: 44px;
  @include flexbox($justify-content: center, $align-content: center);

  @include xl-min() {
    top: 5px;
    right: 5px;
  }

  .icon {
    width: 25px;
    height: auto;

    @include xl-min() {
      width: 25px;
    }
  }
}
.lightbox__content {
  background: white;
  padding: 25px;
  margin: 25px;
  height: calc(100vh - 50px);

  @include xl-min() {
    padding: 0;
    margin: 45px 90px;
    height: calc(100vh - 90px);
  }
}
